<template>
  <div>
    <div class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 justify-between">
          <h3 class="box-title">Coin Health</h3>
          <div class="flex flex-col-sm gap-8 align-items-center">
            <span class="text-bold text-danger" v-html="searching"></span>
            <input v-model="keyword" aria-label="search" class="form-control"
                   placeholder="Search..." type="text" ref="searchInput"
                   @keyup="search($event)"/>
            <div class="flex gap-8 align-items-center">
              <label class="mb-0 no-wrap">
                <input v-model="buy" name="buy" type="radio"
                       value="1" @change="searchRecords()"/> Buy Enabled</label>
              <label class="mb-0 no-wrap">
                <input v-model="buy" name="buy" type="radio"
                       value="0" @change="searchRecords()"/> Buy Disabled</label>
            </div>
            <div class="flex gap-8 align-items-center">
              <label class="mb-0 no-wrap">
                <input v-model="isOnline" name="isOnline" type="radio"
                       value="1" @change="searchRecords()"/> Online</label>
              <label class="mb-0 no-wrap">
                <input v-model="isOnline" name="isOnline" type="radio"
                       value="0" @change="searchRecords()"/> Offline</label>
              <label class="mb-0 no-wrap">
                <input v-model="isOnline" name="isOnline" type="radio"
                       value="-1" @change="searchRecords()"/> Both</label>
            </div>
            <button class="btn btn-primary btn-flat"
              @click="launchAddAltCoinModal()">Add AltCoin</button>
          </div>
        </div>
      </div>
      <div class="box-body">
        <coin-health-list v-if="!isLoading" :meta="meta" :records="records"
                          :sortKey="sortKey" :orderBy="orderBy" :keyword="keyword"
                          @loader="updateLoader"
                          @updatePage="updatePage" @sort="sort"></coin-health-list>
        <va-loader v-if="isLoading"></va-loader>
      </div>
    </div>
    <!-- Update AltCoin Modal -->
    <update-alt-coin-modal></update-alt-coin-modal>
    <!-- Add AltCoin Modal -->
    <alt-coin-modal></alt-coin-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateDiffInHours, diffInHuman, saveNotification } from "@/lib/utils";
import VaLoader from "@/components/partials/Loader";
import CoinHealthList from "@/components/CoinHealthList";
import UpdateAltCoinModal from "@/components/modals/UpdateAltCoinModal";
import AltCoinModal from "@/components/modals/AltCoinModal";
import { EXCHANGE_LIST } from "@/store/keys";

export default {
  name: "CoinHealth",
  components: {
    AltCoinModal,
    UpdateAltCoinModal,
    CoinHealthList,
    VaLoader,
  },
  data() {
    return {
      type: "",
      isLoading: false,
      records: [],
      keyword: "",
      isOnline: 1,
      buy: 1,
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      timer: null,
      searching: "",
      sortKey: "LastKnownCheck,LastKnownTickerCheck,LastPaymentCheck",
      orderBy: "ASC",
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
    ]),
  },
  methods: {
    updateLoader(val) {
      this.searching = val;
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.expandedRowIndex = 0;
          this.getRecords(1);
        }, 500);
      }
    },
    searchRecords() {
      this.expandedRowIndex = 0;
      this.getRecords();
    },
    updatePage(page) {
      this.getRecords(page);
    },
    sort(sort) {
      this.sortKey = sort.sortKey;
      this.orderBy = sort.orderBy;
      this.getRecords(1);
    },
    getRecords(page = 1, searching = false) {
      this.isLoading = true;
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListCoinHealth`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          online: this.isOnline,
          buy: this.buy,
          type: this.type,
          limit: this.meta.limit,
          order: this.sortKey,
          orderBy: this.orderBy,
        },
      }).then(response => {
        if (response.status === 200) {
          this.isLoading = false;
          this.searching = "";
          this.records = response.data.data.records;
          if (this.records) {
            this.records = this.records.map(record => {
              return {
                ...record,
                LastKnownCheckInHrs: this.dateDiffInHours(record.LastKnownCheck),
                LastKnownCheckInHuman: this.diffInHuman(record.LastKnownCheck),
                LastKnownTickerCheckInHrs: this.dateDiffInHours(record.LastKnownTickerCheck),
                LastKnownTickerCheckInHuman: this.diffInHuman(record.LastKnownTickerCheck),
                LastPaymentCheckInHrs: this.dateDiffInHours(record.LastPaymentCheck),
                LastPaymentCheckInHuman: this.diffInHuman(record.LastPaymentCheck),
                TradeBuyUpdateInHrs: this.dateDiffInHours(record.TradeBuyUpdate),
                TradeBuyUpdateInHuman: this.diffInHuman(record.TradeBuyUpdate),
                TradeSellUpdateInHrs: this.dateDiffInHours(record.TradeSellUpdate),
                TradeSellUpdateInHuman: this.diffInHuman(record.TradeSellUpdate),
              };
            });
          }
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.isLoading = false;
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.response.data);
      });
    },
    dateDiffInHours(val) {
      return dateDiffInHours(val);
    },
    diffInHuman(val) {
      return diffInHuman(val);
    },
    launchAddAltCoinModal() {
      this.$bus.$emit("altCoin-modal", {
        title: "Add AltCoin",
        data: null,
        confirm: () => {
          if (this.tab === "vFiat") {
            this.getRecords();
          }
        },
      });
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.getRecords();
    this.$store.dispatch(EXCHANGE_LIST);
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
  },
};
</script>

<style scoped></style>
