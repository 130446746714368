<template>
  <div id="bsModalAltCoin" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="currency name" rules="required"
                tag="div">
                <label for="currencyName">Currency Name</label>
                <input id="currencyName" v-model="form.CurrencyName" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="short name" rules="required|min:3|max:3"
                tag="div">
                <label for="shortName">Short Name</label>
                <input id="shortName" v-model="form.CurrencyCodeShort" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="long name" rules="required|max:10"
                tag="div">
                <label for="longName">Long Name</label>
                <input id="longName" v-model="form.CurrencyCodeLong" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="exchange" rules="required" tag="div">
                <label for="exchange">Exchange</label>
                <select id="exchange" v-model="form.Exchange" class="form-control">
                  <option v-for="(record, index) in exchangeList" :key="index" :value="record.Name.toLowerCase()">
                    {{ record.Name }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool master" rules="required" tag="div">
                <label for="poolMaster">Pool Master</label>
                <select id="poolMaster" v-model="form.PoolMaster" class="form-control">
                  <option v-for="(record, index) in exchangeList" :key="index" :value="record.ID">{{ record.Name }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool poloniex" rules="required"
                tag="div">
                <label for="poolPoloniex">Pool Poloniex</label>
                <select id="poolPoloniex" v-model="form.PoolPoloniex" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool bitfinex" rules="required"
                tag="div">
                <label for="poolBitfinex">Pool Bitfinex</label>
                <select id="poolBitfinex" v-model="form.PoolBitfinex" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool okx" rules="required" tag="div">
                <label for="poolOKX">Pool OKX</label>
                <select id="poolOKX" v-model="form.PoolOKX" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool yobit" rules="required" tag="div">
                <label for="poolYobit">Pool Yobit</label>
                <select id="poolYobit" v-model="form.PoolYobit" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool kucoin" rules="required" tag="div">
                <label for="poolkucoin">Pool kucoin</label>
                <select id="poolkucoin" v-model="form.Poolkucoin" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool binance" rules="required" tag="div">
                <label for="poolBinance">Pool Binance</label>
                <select id="poolBinance" v-model="form.PoolBinance" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool hitbtc" rules="required" tag="div">
                <label for="poolHitbtc">Pool Hitbtc</label>
                <select id="poolHitbtc" v-model="form.PoolHitbtc" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="pool ether" rules="required" tag="div">
                <label for="poolEther">Pool Ether</label>
                <select id="poolEther" v-model="form.PoolEther" class="form-control">
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="fiat bridge" rules="required" tag="div">
                <label for="krakenFiatBridge">Select Available FiatBridge</label>
                <select id="krakenFiatBridge" v-model="form.KrakenFiatBridge" class="form-control">
                  <option v-for="exchange in onlineExchangesList" :key="exchange.ID" :value="exchange.ID">
                    {{ exchange.Name }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span>Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin ml-8"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "AltCoinModal",
  data() {
    return {
      title: "",
      isLoading: false,
      form: {
        CurrencyName: "",
        CurrencyCodeShort: "",
        CurrencyCodeLong: "",
        Exchange: "",
        PoolMaster: "",
        PoolPoloniex: "",
        PoolBitfinex: "",
        PoolOKX: "",
        PoolYobit: "",
        Poolkucoin: "",
        PoolBinance: "",
        PoolEther: "",
        KrakenFiatBridge: "",
        PoolHitbtc: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "exchangeList",
    ]),
    onlineExchangesList() {
      return this.exchangeList.filter(e => e.Online);
    },
  },
  methods: {
    confirm() {
      this.confirmHook();
      this.close();
    },
    onSubmit() {
      this.isLoading = true;
      let payload = JSON.parse(JSON.stringify(this.form));
      payload = Object.assign(payload, {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        output: "json",
      });
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=AddAltCoinRowItem`, this.qs.stringify(payload), {}).then(response => {
        this.isLoading = false;
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.$toast.fire("", response.data.message, "success");
            saveNotification(response.data.message);
            this.confirm();
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            this.close();
            logout();
          }
        }
      }).catch(error => {
        this.isLoading = false;
        this.$toast.fire("", error.data, "error");
        saveNotification(error.data);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalAltCoin").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("altCoin-modal", (args) => {
      this.title = args.title;
      this.data = args.data;
      if (this.data) {
        this.form = {
          CurrencyName: this.data.Name,
          CurrencyCodeShort: this.data.ShortName,
          CurrencyCodeLong: this.data.LongName,
          Exchange: 0,
          PoolMaster: 0,
          PoolPoloniex: 0,
          PoolBitfinex: 0,
          PoolOKX: 0,
          PoolYobit: 0,
          Poolkucoin: 0,
          PoolBinance: 0,
          PoolEther: 0,
          KrakenFiatBridge: 0,
          PoolHitbtc: 0,
        };
      }
      this.confirmHook = args.confirm;
      $("#bsModalAltCoin").modal("show");
    });
  },
};
</script>

<style scoped></style>
