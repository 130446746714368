var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"bsModalAltCoin","aria-labelledby":"bsModalLabel","data-backdrop":"static","data-keyboard":"false","data-toggle":"modal","role":"dialog","tabindex":"-1"},on:{"click":function($event){return _vm.clickOverlay()}}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"aria-label":"Close","type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("× ")])]),_c('h4',{staticClass:"modal-title",attrs:{"id":"bsModalLabel"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"currency name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"currencyName"}},[_vm._v("Currency Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.CurrencyName),expression:"form.CurrencyName"}],staticClass:"form-control",attrs:{"id":"currencyName","type":"text"},domProps:{"value":(_vm.form.CurrencyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "CurrencyName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"short name","rules":"required|min:3|max:3","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"shortName"}},[_vm._v("Short Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.CurrencyCodeShort),expression:"form.CurrencyCodeShort"}],staticClass:"form-control",attrs:{"id":"shortName","type":"text"},domProps:{"value":(_vm.form.CurrencyCodeShort)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "CurrencyCodeShort", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"long name","rules":"required|max:10","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"longName"}},[_vm._v("Long Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.CurrencyCodeLong),expression:"form.CurrencyCodeLong"}],staticClass:"form-control",attrs:{"id":"longName","type":"text"},domProps:{"value":(_vm.form.CurrencyCodeLong)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "CurrencyCodeLong", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"exchange","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"exchange"}},[_vm._v("Exchange")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Exchange),expression:"form.Exchange"}],staticClass:"form-control",attrs:{"id":"exchange"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Exchange", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.exchangeList),function(record,index){return _c('option',{key:index,domProps:{"value":record.Name.toLowerCase()}},[_vm._v(" "+_vm._s(record.Name)+" ")])}),0),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool master","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolMaster"}},[_vm._v("Pool Master")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolMaster),expression:"form.PoolMaster"}],staticClass:"form-control",attrs:{"id":"poolMaster"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolMaster", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.exchangeList),function(record,index){return _c('option',{key:index,domProps:{"value":record.ID}},[_vm._v(_vm._s(record.Name)+" ")])}),0),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool poloniex","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolPoloniex"}},[_vm._v("Pool Poloniex")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolPoloniex),expression:"form.PoolPoloniex"}],staticClass:"form-control",attrs:{"id":"poolPoloniex"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolPoloniex", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool bitfinex","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolBitfinex"}},[_vm._v("Pool Bitfinex")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolBitfinex),expression:"form.PoolBitfinex"}],staticClass:"form-control",attrs:{"id":"poolBitfinex"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolBitfinex", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool okx","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolOKX"}},[_vm._v("Pool OKX")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolOKX),expression:"form.PoolOKX"}],staticClass:"form-control",attrs:{"id":"poolOKX"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolOKX", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool yobit","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolYobit"}},[_vm._v("Pool Yobit")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolYobit),expression:"form.PoolYobit"}],staticClass:"form-control",attrs:{"id":"poolYobit"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolYobit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool kucoin","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolkucoin"}},[_vm._v("Pool kucoin")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Poolkucoin),expression:"form.Poolkucoin"}],staticClass:"form-control",attrs:{"id":"poolkucoin"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Poolkucoin", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool binance","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolBinance"}},[_vm._v("Pool Binance")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolBinance),expression:"form.PoolBinance"}],staticClass:"form-control",attrs:{"id":"poolBinance"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolBinance", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool hitbtc","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolHitbtc"}},[_vm._v("Pool Hitbtc")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolHitbtc),expression:"form.PoolHitbtc"}],staticClass:"form-control",attrs:{"id":"poolHitbtc"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolHitbtc", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"pool ether","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"poolEther"}},[_vm._v("Pool Ether")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.PoolEther),expression:"form.PoolEther"}],staticClass:"form-control",attrs:{"id":"poolEther"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "PoolEther", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("0")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"fiat bridge","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"krakenFiatBridge"}},[_vm._v("Select Available FiatBridge")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.KrakenFiatBridge),expression:"form.KrakenFiatBridge"}],staticClass:"form-control",attrs:{"id":"krakenFiatBridge"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "KrakenFiatBridge", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.onlineExchangesList),function(exchange){return _c('option',{key:exchange.ID,domProps:{"value":exchange.ID}},[_vm._v(" "+_vm._s(exchange.Name)+" ")])}),0),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat",attrs:{"disabled":_vm.isLoading,"type":"submit"}},[_c('span',[_vm._v("Save")]),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin ml-8"})]):_vm._e()])])],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }