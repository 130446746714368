<template>
  <div>
    <div v-if="meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
    <div class="table-responsive">
      <table class="table table-condensed table-striped">
        <thead>
          <tr class="no-wrap">
            <th></th>
            <th class="pointer" @click="sort('ID')">
              ID
              <span v-if="sortKey === 'ID'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Tag')">
              Tag
              <span v-if="sortKey === 'Tag'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('SecureCloud')">
              Type
              <span v-if="sortKey === 'SecureCloud'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Name')">
              Name
              <span v-if="sortKey === 'Name'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th style="min-width:165px">Actions</th>
            <th class="pointer" @click="sort('LongName')">
              Long Name
              <span v-if="sortKey === 'LongName'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Online')">
              Online
              <span v-if="sortKey === 'Online'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('Buy')">
              Buy
              <span v-if="sortKey === 'Buy'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('DailyBTCPriceTick')">
              Daily BTC Price Tick
              <span v-if="sortKey === 'DailyBTCPriceTick'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('DisableDeposit')">
              Deposit
              <span v-if="sortKey === 'DisableDeposit'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('DisablePayout')">
              Payout
              <span v-if="sortKey === 'DisablePayout'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('DisableBankPayout')">
              Bank Payout
              <span v-if="sortKey === 'DisableBankPayout'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('LastKnownCheck')">
              Last Host Check
              <span v-if="sortKey === 'LastKnownCheck'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('LastKnownTickerCheck')">
              Last Ticker Check
              <span v-if="sortKey === 'LastKnownTickerCheck'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('LastPaymentCheck')">
              Last Payment Check
              <span v-if="sortKey === 'LastPaymentCheck'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
            <th class="pointer" @click="sort('TradeBuyUpdate')">
              Trade Update
              <span v-if="sortKey === 'TradeBuyUpdate'" class="ml-4">
                <i class="fa fa-sort"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="records.length > 0">
            <template v-for="(record, index) in records">
              <tr :key="index">
                <td>
                  <span class="flex pointer text-success" @click="toggleRow(index)">
                    <svg v-if="expandedRowIndex !== index + 1" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <svg v-if="expandedRowIndex === index + 1" class="w-24 h-24" fill="none" stroke="currentColor"
                      viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2"></path>
                    </svg>
                  </span>
                </td>
                <td>
                  <span class="flex">
                    <router-link v-if="record.SecureCloud === 1 || record.SecureCloud === 3"
                      :to="{ name: 'coin-health-detail', params: { id: record.ID } }" tag="a">
                      {{ record.ID }}
                    </router-link>
                    <span v-else>{{ record.ID }}</span>
                    <router-link :to="{ name: 'payouts', query: { id: record.ID } }" tag="a">
                      <i class="fa fa-money ml-8"></i>
                    </router-link>
                    <router-link
                      :to="{ name: 'balance-sheet', query: { id: record.ID, buy: record.Buy, online: record.Online } }"
                      tag="a">
                      <i class="fa fa-globe ml-8"></i>
                    </router-link>
                  </span>
                </td>
                <td>
                  <span class="pointer" @click="launchUpdateAltCoinModal(record, 'Tag', 'Tag', 'text')">
                    <span v-html="formatStatus(record.Tag, 'tag')"></span>
                  </span>
                </td>
                <td>
                  <span class="pointer"
                    @click="launchUpdateAltCoinModal(record, 'SecureCloud', 'SecureCloud', 'select')">
                    {{ getCurrencyType(record.SecureCloud) }}
                  </span>
                </td>
                <td class="no-wrap">
                  <router-link :to="{ name: 'coin-health-detail', params: { id: record.ID }, query: { tb: 'xChange' } }"
                    tag="a">
                    <img :src="generateIconUrl(record)" alt="" class="w-48 mx-8" />
                  </router-link>
                  <span class="pointer" @click="launchUpdateAltCoinModal(record, 'Name', 'Name', 'text')">
                    {{ record.Name }}
                  </span>
                  <router-link v-if="keyword !== '' && (record.SecureCloud === 1 || record.SecureCloud === 3)"
                    :to="{ name: 'coin-health-detail', params: { id: record.ID }, query: { tb: 'xChange', 'keyword': keyword } }"
                    class="ml-8" tag="a"><i class="fa fa-search"></i></router-link>
                </td>
                <td>
                  <template v-if="record.Online">
                    <button v-tooltip="{ content: 'Refresh Balance', trigger: 'click hover focus' }"
                      class="btn btn-info btn-sm btn-flat ml-8" @click="refreshBalance(record, index)">B
                    </button>
                    <button v-tooltip="{ content: 'PoolCheck Only', trigger: 'click hover focus' }"
                      class="btn btn-info btn-sm btn-flat ml-8" @click="poolCheck(record, index)">P
                    </button>
                    <button v-tooltip="{ content: 'PoolCheck & Consolidation', trigger: 'click hover focus' }"
                      class="btn btn-info btn-sm btn-flat ml-8" @click="poolCheckAndConsolidation(record, index)">C
                    </button>
                    <button v-tooltip="{ content: 'Update AltCoin', trigger: 'click hover focus' }"
                      class="btn btn-info btn-sm btn-flat ml-8" @click="launchUpdateAltCoin(record, index)">U
                    </button>
                  </template>
                </td>
                <td>
                  <span class="pointer" @click="launchUpdateAltCoinModal(record, 'LongName', 'Long Name', 'text')">
                    {{ record.LongName }}
                  </span>
                </td>
                <td>
                  <span @click="updateToggle(record, 'Online')" v-html="formatStatus(record.Online, 'online')"></span>
                </td>
                <td>
                  <span @click="updateToggle(record, 'Buy')" v-html="formatStatus(record.Buy, 'buy')"></span>
                </td>
                <td>
                  <span class="pointer"
                    @click="launchUpdateAltCoinModal(record, 'DailyBTCPriceTick', 'Daily BTC Price Tick', 'text')">
                    {{ toFixed(record.DailyBTCPriceTick) }}
                  </span>
                  <span class="pointer ml-8" @click="toConverter(toFixed(record.DailyBTCPriceTick), 1)">
                    <i class="fa fa-copy"></i>
                  </span>
                </td>
                <td>
                  <span @click="updateToggle(record, 'DisableDeposit')"
                    v-html="formatStatus(record.DisableDeposit, 'deposit')"></span>
                </td>
                <td>
                  <span @click="updateToggle(record, 'DisablePayout')"
                    v-html="formatStatus(record.DisablePayout, 'payout')"></span>
                </td>
                <td>
                  <span @click="updateToggle(record, 'DisableBankPayout')"
                    v-html="formatStatus(record.DisableBankPayout, 'payoutBank')"></span>
                </td>
                <td :class="getBgClass(record.LastKnownCheckInHrs)" class="no-wrap">
                  {{ record.LastKnownCheckInHuman }}
                </td>
                <td :class="getBgClass(record.LastKnownTickerCheckInHrs)" class="no-wrap">
                  {{ record.LastKnownTickerCheckInHuman }}
                </td>
                <td :class="getBgClass(record.LastPaymentCheckInHrs)" class="no-wrap">
                  {{ record.LastPaymentCheckInHuman }}
                </td>
                <td :class="getBgClass(record.TradeBuyUpdateInHrs)" class="no-wrap">
                  {{ record.TradeBuyUpdateInHuman }}
                </td>
              </tr>
              <tr v-if="expandedRowIndex === index + 1" :key="'detail_' + index">
                <td colspan="14">
                  <table class="table table-condensed table-striped mb-0">
                    <tbody>
                      <tr>
                        <td style="width: 200px">Short Name</td>
                        <td style="width: 25%">
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'ShortName', 'Short Name', 'text')">
                            {{ record.ShortName }}
                          </span>
                        </td>
                        <td>Input Currency</td>
                        <td>
                          <span @click="updateToggle(record, 'IsInputCurrency')"
                            v-html="formatStatus(record.IsInputCurrency, 'inputCurrency')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Block Explorer</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'BlockExplorer', 'Block Explorer', 'text')">
                            {{ record.BlockExplorer }}
                          </span>
                        </td>
                        <td>Virtual Currency</td>
                        <td>
                          <span @click="updateToggle(record, 'VirtualCurrency')"
                            v-html="formatStatus(record.VirtualCurrency, 'virtualCurrency')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Decimal Scale</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'DecimalScale', 'Decimal Scale', 'text')">
                            {{ record.DecimalScale }}
                          </span>
                        </td>
                        <td>vFiat</td>
                        <td>
                          <span @click="updateToggle(record, 'vFiat')"
                            v-html="formatStatus(record.vFiat, 'vFiat')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Miner Fee</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'MinerFee', 'Miner Fee', 'text')">
                            {{ toFixed(record.MinerFee) }}
                          </span>
                        </td>
                        <td>Exchange</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'Exchange', 'Exchange', 'text')">
                            {{ record.Exchange ? record.Exchange : 'NA' }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Send Fee</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'SendFee', 'Send Fee', 'text')">
                            {{ toFixed(record.SendFee) }}
                          </span>
                        </td>
                        <td>Interest</td>
                        <td>
                          <span @click="updateToggle(record, 'Interest')"
                            v-html="formatStatus(record.Interest, 'interest')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Minimum Payout</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'MinimumPayout', 'Minimum Payout', 'text')">
                            {{ toFixed(record.MinimumPayout) }}
                          </span>
                        </td>
                        <td>Interest Rate</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'InterestRate', 'Interest Rate', 'text')">
                            {{ record.InterestRate }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Send Fee Low</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'SendFeeLow', 'Send Fee Low', 'text')">
                            {{ record.SendFeeLow }}
                          </span>
                        </td>
                        <td>Paprika Coin Info</td>
                        <td>
                          <span class="pointer" style="word-break: break-all"
                            @click="launchUpdateAltCoinModal(record, 'PaprikaCoinInfo', 'Paprika Coin Info', 'text')">
                            {{ record.PaprikaCoinInfo || '-' }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Fee Address</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'FeeAddress', 'Fee Address', 'text')">
                            {{ record.FeeAddress }}
                          </span>
                        </td>
                        <td>Expiry Time</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'ExpiryTime', 'Expiry Time', 'text')">
                            {{ record.ExpiryTime + ' minutes' }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Kraken Name</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'KrakenName', 'KrakenName', 'text')">
                            {{ record.KrakenName || '-' }}
                          </span>
                        </td>
                        <td>LTR</td>
                        <td>
                          <span @click="updateToggle(record, 'LTR')" v-html="formatStatus(record.LTR, 'ltr')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Fiat Bridge Fiat Name</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'FiatBridgeFiatName', 'Fiat Bridge Fiat Name', 'text')">
                            {{ record.FiatBridgeFiatName || '-' }}
                          </span>
                        </td>
                        <td>LTR Balance</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'LTRBalance', 'LTR Balance', 'text')">
                            {{ toFixed(record.LTRBalance) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Fiat Bridge Coin Balance</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'FiatBridgeCoinBalance', 'Fiat Bridge Coin Balance', 'text')">
                            {{ toFixed(record.FiatBridgeCoinBalance) }}
                          </span>
                        </td>
                        <td>Trading</td>
                        <td>
                          <span @click="updateToggle(record, 'DisableTrading')"
                            v-html="formatStatus(record.DisableTrading, 'trading')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Fiat Bridge Fiat Balance</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'FiatBridgeFiatBalance', 'Fiat Bridge Fiat Balance', 'text')">
                            {{ toFixed(record.FiatBridgeFiatBalance) }}
                          </span>
                        </td>
                        <td>Trading Engine</td>
                        <td>
                          <span @click="updateToggle(record, 'DisableTradingEngine')"
                            v-html="formatStatus(record.DisableTradingEngine, 'tradingEngine')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Support Mailbox</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'SupportMailbox', 'Support Mailbox', 'text')">
                            {{ record.SupportMailbox }}
                          </span>
                        </td>
                        <td>Trade Low Risk High Spread</td>
                        <td>
                          <span @click="updateToggle(record, 'TradeLowRiskHighSpread')"
                            v-html="formatStatus(record.TradeLowRiskHighSpread, 'risk')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Support Detail</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'SupportDetail', 'Support Detail', 'textarea')">
                            <textarea v-model="record.SupportDetail" class="form-control" readonly rows="3"></textarea>
                          </span>
                        </td>
                        <td>Get Info</td>
                        <td>
                          <span @click="updateToggle(record, 'GetInfo')"
                            v-html="formatStatus(record.GetInfo, 'info')"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>Available For Autonomy</td>
                        <td>
                          <span @click="updateToggle(record, 'AvailableForAutonomy')"
                            v-html="formatStatus(record.AvailableForAutonomy, 'available')"></span>
                        </td>
                        <td>Exclude From List</td>
                        <td>
                          <span @click="updateToggle(record, 'ExcludeFromList')"
                            v-html="formatStatus(record.ExcludeFromList, 'exclude')"></span>
                        </td>
                      </tr>
                      <template v-if="record.SecureCloud === 1">
                        <tr>
                          <td>SecureCloud</td>
                          <td>{{ record.SecureCloud }}</td>
                          <td>SecureCloud User</td>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal(record, 'SecureCloudUser', 'Secure Cloud User', 'text')">
                              {{ record.SecureCloudUser }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>SecureCloud Password</td>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal(record, 'SecureCloudPassword', 'Secure Cloud Password', 'text')">
                              {{ record.SecureCloudPassword }}
                            </span>
                          </td>
                          <td>SecureCloud Server</td>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal(record, 'SecureCloudServer', 'Secure Cloud Server', 'text')">
                              {{ record.SecureCloudServer }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>SecureCloud Master Address</td>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal(record, 'SecureCloudMasterAddress', 'Secure Cloud Master Address', 'text')">
                              {{ record.SecureCloudMasterAddress }}
                            </span>
                          </td>
                          <td>SecureCloud Wallet Backup</td>
                          <td>
                            <span class="pointer"
                              @click="launchUpdateAltCoinModal(record, 'SCWalletBackup', 'SC Wallet Backup', 'text')">
                              {{ record.SCWalletBackup }}
                            </span>
                          </td>
                        </tr>
                      </template>
                      <tr>
                        <td>Unified Crypto Asset ID</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'unified_cryptoasset_id', 'Unified Crypto Asset ID', 'text')">
                            {{ record.unified_cryptoasset_id }}
                          </span>
                        </td>
                        <td>BTC not USDT Ticker</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'BTCnotUSDTTicker', 'BTC not USDT Ticker', 'text')">
                            {{ record.BTCnotUSDTTicker }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Last Known State</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'LastKnownState', 'Last Known State', 'text')">
                            {{ record.LastKnownState }}
                          </span>
                        </td>
                        <td>Last Known Node Failure Count</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'LastKnownNodeFailureCount', 'Last Known Node Failure Count', 'text')">
                            {{ record.LastKnownNodeFailureCount }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Last Known Ticker Failure Count</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'LastKnownTickerFailureCount', 'Last Known Ticker Failure Count', 'text')">
                            {{ record.LastKnownTickerFailureCount }}
                          </span>
                        </td>
                        <td>Offline Payment Check URL</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'OfflinePaymentCheckURL', 'Offline Payment Check URL', 'text')">
                            {{ record.OfflinePaymentCheckURL || '-' }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Coinectar Total Balance</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'CoinectarTotalBalance', 'Coinectar Total Balance', 'text')">
                            {{ record.CoinectarTotalBalance }}
                          </span>
                        </td>
                        <td>Trade Buy</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'TradeBuy', 'Trade Buy', 'text')">
                            {{ record.TradeBuy }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>No Exchange Pool</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'NoExchangePool', 'No Exchange Pool', 'text')">
                            {{ record.NoExchangePool }}
                          </span>
                        </td>
                        <td>Trade Sell</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'TradeSell', 'Trade Sell', 'text')">
                            {{ record.TradeSell }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Payment Check Interval</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'PaymentCheckInterval', 'Payment Check Interval', 'text')">
                            {{ record.PaymentCheckInterval }}
                          </span>
                        </td>
                        <td>Reverse Trade Pair</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'ReverseTradePair', 'Reverse Trade Pair', 'text')">
                            {{ record.ReverseTradePair }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Chain</td>
                        <td>
                          <span class="pointer" @click="launchUpdateAltCoinModal(record, 'Chain', 'Chain', 'text')">
                            {{ record.Chain }}
                          </span>
                        </td>
                        <td>Chain Name</td>
                        <td>
                          <span class="pointer"
                            @click="launchUpdateAltCoinModal(record, 'ChainName', 'Chain Name', 'text')">
                            {{ record.ChainName || '-' }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td colspan="14">No record found!</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="meta.total" class="footer">
      <pagination :meta="meta" @pageChange="updatePage"></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  dateDiffInHours,
  diffInHuman,
  generateIconUrl,
  getOptions,
  logout,
  saveNotification,
  toFixed,
} from "@/lib/utils";
import { mapGetters } from "vuex";
import { SAVE_CONVERTER } from "@/store/keys";

export default {
  name: "CoinHealthList",
  props: {
    records: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    meta: {
      type: Object,
      required: true,
      default() {
        return {
          total: 0,
          page: 1,
          offset: 0,
          limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
        };
      },
    },
    orderBy: {
      type: String,
      required: true,
      default: "ASC",
    },
    sortKey: {
      type: String,
      required: true,
      default: "LastKnownCheck, LastKnownTickerCheck, LastPaymentCheck",
    },
    keyword: {
      default: "",
    },
  },
  data() {
    return {
      expandedRowIndex: 0,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    ...mapGetters([
      "converter",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(function () {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    },
    resetIndex() {
      this.expandedRowIndex = 0;
    },
    toggleRow(i) {
      i++;
      this.expandedRowIndex = this.expandedRowIndex === i ? 0 : i;
    },
    updatePage(page) {
      this.$emit("updatePage", page);
    },
    generateStatusLabel(key, status) {
      let label = "";
      switch (key) {
        case "ltr":
          label = status ? "On" : "Off";
          break;
        case "buy":
          label = status ? "Enabled" : "Disabled";
          break;
        case "online":
          label = status ? "Online" : "Offline";
          break;
        case "deposit":
        case "payout":
        case "trading":
        case "tradingEngine":
        case "payoutBank":
          label = status ? "Disabled" : "Enabled";
          break;
        case "inputCurrency":
        case "virtualCurrency":
        case "vFiat":
        case "interest":
        case "interestRate":
        case "info":
        case "available":
        case "risk":
        case "exclude":
        case "tag":
          label = status ? "Yes" : "No";
          break;
      }
      return label;
    },
    generateStatusClass(key, status) {
      let lblClass = "";
      switch (key) {
        case "online":
        case "buy":
        case "ltr":
        case "inputCurrency":
        case "virtualCurrency":
        case "vFiat":
        case "interest":
        case "interestRate":
        case "info":
        case "available":
        case "risk":
        case "tag":
          lblClass = status ? "label-success" : "label-danger";
          break;
        case "deposit":
        case "payout":
        case "trading":
        case "tradingEngine":
        case "exclude":
        case "payoutBank":
          lblClass = status ? "label-danger" : "label-success";
          break;
      }
      return lblClass;
    },
    getCurrencyType(type) {
      let label = "";
      switch (type) {
        case 1:
          label = "SecureCloud";
          break;
        case 2:
          label = "Fiat";
          break;
        case 3:
          label = "XChange";
          break;
      }
      return label;
    },
    formatStatus(status, key) {
      const formattedStatus = this.generateStatusLabel(key, status);
      const lblClass = this.generateStatusClass(key, status);
      return "<label class=\"label " + lblClass + " pointer\">" + formattedStatus + "</label>";
    },
    toFixed(val) {
      return toFixed(val);
    },
    generateIconUrl(record) {
      return generateIconUrl(record.Name);
    },
    getBgClass(val) {
      return val <= 1 ? "bg-success" : "bg-danger";
    },
    getOptions(key) {
      return getOptions(key);
    },
    sort(key) {
      const orderBy = this.orderBy === "asc" ? "desc" : "asc";
      this.$emit("sort", {
        sortKey: key,
        orderBy: orderBy,
      });
    },
    dateDiffInHours(val) {
      return dateDiffInHours(val);
    },
    diffInHuman(val) {
      return diffInHuman(val);
    },
    getAltCoinHealthById(record) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=AltCoinHealth`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id: record.ID,
        },
      }).then(response => {
        if (response.status === 200) {
          let coinHealth = response.data.data;
          if (coinHealth) {
            coinHealth = {
              ...coinHealth,
              LastKnownCheckInHrs: this.dateDiffInHours(coinHealth.LastKnownCheck),
              LastKnownCheckInHuman: this.diffInHuman(coinHealth.LastKnownCheck),
              LastKnownTickerCheckInHrs: this.dateDiffInHours(coinHealth.LastKnownTickerCheck),
              LastKnownTickerCheckInHuman: this.diffInHuman(coinHealth.LastKnownTickerCheck),
              LastPaymentCheckInHrs: this.dateDiffInHours(coinHealth.LastPaymentCheck),
              LastPaymentCheckInHuman: this.diffInHuman(coinHealth.LastPaymentCheck),
              TradeBuyUpdateInHrs: this.dateDiffInHours(coinHealth.TradeBuyUpdate),
              TradeBuyUpdateInHuman: this.diffInHuman(coinHealth.TradeBuyUpdate),
              TradeSellUpdateInHrs: this.dateDiffInHours(coinHealth.TradeSellUpdate),
              TradeSellUpdateInHuman: this.diffInHuman(coinHealth.TradeSellUpdate),
            };
            const index = this._.findIndex(this.records, { ID: coinHealth.ID });
            if (index !== -1) {
              this.records.splice(index, 1, coinHealth);
            }
          }
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
      });
    },
    updateToggle(record, key) {
      const val = Number(record[key]) === 1 ? 0 : 1;
      const form = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        AltCoinID: record.ID,
        Key: key,
        Value: val,
        output: "json",
      };
      const payload = JSON.parse(JSON.stringify(form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=AltCoinUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getAltCoinHealthById(record);
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    launchUpdateAltCoinModal(record, key, label, type) {
      const keys = ["DailyBTCPriceTick", "LTRBalance", "FiatBridgeCoinBalance", "FiatBridgeFiatBalance", "MinimumPayout"];
      const options = this.getOptions(key);
      this.$bus.$emit("update-altCoin-modal", {
        title: "Update " + label,
        valueLabel: label,
        altCoin: {
          ID: record.ID,
          Key: key,
          Value: keys.indexOf(key) !== -1 ? toFixed(record[key]) : record[key],
        },
        type,
        options,
        confirm: () => {
          this.getAltCoinHealthById(record);
        },
      });
    },
    launchUpdateAltCoin(record, index) {
      this.$bus.$emit("altCoin-modal", {
        title: "Update AltCoin",
        data: record,
        confirm: () => {
          if (this.tab === "vFiat") {
            this.getRecords();
          }
        },
      });
    },
    async poolCheck(record, index) {
      const result = await this.validate("Pool check only");
      if (result) {
        this.$emit("loader", "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Please wait...");
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
          PoolCheck: 1,
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          this.$emit("loader", "");
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.$emit("loader", "");
          console.log(error);
        });
      }
    },
    async poolCheckAndConsolidation(record, index) {
      const result = await this.validate("Pool check and consolidate");
      if (result) {
        this.$emit("loader", "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Please wait...");
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
          PoolCheck: 1,
          Consolidate: 1,
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          this.$emit("loader", "");
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.$emit("loader", "");
          console.log(error);
        });
      }
    },
    refreshBalance(record, index) {
      this.$swal.fire({
        text: "Refresh Balance?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusConfirm: true,
      }).then((result) => {
        this.loader = "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Please wait...";
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          Secure: "AA",
          Buy: record.Buy ? 1 : 0,
          AltCoinID: record.ID,
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          this.loader = "";
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.loader = "";
          console.log(error);
        });
      });
    },
    // Actions
    async validate(text = "", balance = "", target = "") {
      if (balance !== "") {
        return await this.$swal.fire({
          text: text,
          icon: "warning",
          input: "text",
          inputValue: toFixed(balance),
          showCancelButton: true,
          confirmButtonColor: "#00a65a",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          focusCancel: true,
          inputValidator: (value) => {
            if (!value || value <= 0) {
              return "Balance should be greater than 0";
            } else if (target !== "" && value > target) {
              return "Balance can not be greater than " + toFixed(target);
            }
          },
        }).then(({ value }) => {
          return value;
        });
      } else {
        return await this.$swal.fire({
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00a65a",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          focusCancel: true,
        }).then(({ value }) => {
          return !!(value && value === true);
        });
      }
    },
  },
};
</script>

<style scoped></style>
